import { ClaimStatus } from 'interfaces/claimInterface';
import { CurrencyUnit, Params } from 'interfaces/general';
import { LiveItemStatus, Medium, PersonRole } from 'interfaces/podcastInterface';
import { UserRole, WaitlistStatus } from 'interfaces/userInterface';
import { LogLevel, LogTag } from 'interfaces/logInterface';
import { FeedbackStatus } from 'interfaces/feedbackInterface';
import { ClipType, MediaMimeType, ReportType } from 'interfaces/episodeInterface';
import { HostTag } from 'interfaces/hostInterface';
import { getDurationLabel } from './helpers';

export const INITIAL_PARAMS: Params = { page: 1, limit: 10, sort: '-updatedAt' };

export const APPLE_CATEGORIES = [
  ['Arts', 'Books', 'Design', 'Fashion & Beauty', 'Food', 'Performing Arts', 'Visual Arts'],
  ['Business', 'Careers', 'Entrepreneurship', 'Investing', 'Management', 'Marketing', 'Non-Profit'],
  ['Comedy', 'Comedy Interviews', 'Improv', 'Stand-Up'],
  ['Education', 'Courses', 'How To', 'Language Learning', 'Self-Improvement'],
  ['Fiction', 'Comedy Fiction', 'Drama', 'Science Fiction'],
  [
    'Health & Fitness',
    'Alternative Health',
    'Fitness',
    'Medicine',
    'Mental Health',
    'Nutrition',
    'Sexuality'
  ],
  ['Kids & Family', 'Education for Kids', 'Parenting', 'Pets & Animals', 'Stories for Kids'],
  [
    'Leisure',
    'Animation & Manga',
    'Automotive',
    'Aviation',
    'Crafts',
    'Games',
    'Hobbies',
    'Home & Garden',
    'Video Games'
  ],
  ['Music', 'Music Boostary', 'Music History', 'Music Interviews', 'Musicast'],
  [
    'News',
    'Business News',
    'Daily News',
    'Entertainment News',
    'News Boostary',
    'Politics',
    'Sports News',
    'Tech News'
  ],
  [
    'Religion & Spirituality',
    'Buddhism',
    'Christianity',
    'Hinduism',
    'Islam',
    'Judiasm',
    'Religion',
    'Spirituality '
  ],
  [
    'Science',
    'Astronomy',
    'Chemistry',
    'Earth Sciences',
    'Life Sciences',
    'Mathematics',
    'Natural Sciences',
    'Nature',
    'Physics',
    'Social Sciences'
  ],
  [
    'Society & Culture',
    'Documentary',
    'Personal Journals',
    'Philosophy',
    'Places & Travel',
    'Relationships'
  ],
  [
    'Sports',
    'Baseball',
    'Basketball',
    'Cricket',
    'Fantasy Sports',
    'Football',
    'Golf',
    'Hockey',
    'Rugby',
    'Soccer',
    'Swimming',
    'Tennis',
    'Volleyball',
    'Wilderness',
    'Wrestling'
  ],
  ['TV & Film', 'After Shows', 'Film History', 'Film Interviews', 'Film Reviews', 'TV Reviews'],
  ['Technology'],
  ['True Crime'],
  ['Government'],
  ['History']
];

export const MUSIC_CATEGORIES = [
  'Alternative Rock',
  'College Rock',
  'Experimental Rock',
  'Goth Rock',
  'Grunge',
  'Hardcore Punk',
  'Hard Rock',
  'Indie Rock',
  'New Wave',
  'Progressive Rock',
  'Punk',
  'Shoegaze',
  'Steampunk',
  'Acoustic Blues',
  'Chicago Blues',
  'Classic Blues',
  'Contemporary Blues',
  'Country Blues',
  'Delta Blues',
  'Electric Blues',
  'Lullabies',
  'Sing-Along',
  'Stories',
  'Avant-Garde',
  'Baroque',
  'Chamber Music',
  'Chant',
  'Choral',
  'Classical Crossover',
  'Early Music',
  'High Classical',
  'Impressionist',
  'Medieval',
  'Minimalism',
  'Modern Composition',
  'Opera',
  'Orchestral',
  'Renaissance',
  'Romantic',
  'Wedding Music',
  'Novelty',
  'Standup Comedy',
  'Alternative Country',
  'Americana',
  'Bluegrass',
  'Contemporary Bluegrass',
  'Contemporary Country',
  'Country Gospel',
  'Honky Tonk',
  'Outlaw Country',
  'Traditional Bluegrass',
  'Traditional Country',
  'Urban Cowboy',
  'Breakbeat',
  'Dubstep',
  'Exercise',
  'Garage',
  'Hardcore',
  'Hard Dance',
  'Hi-NRG / Eurodance',
  'House',
  'Jackin House',
  'Jungle/Drum’n bass',
  'Techno',
  'Trance',
  'Bop',
  'Lounge',
  'Swing',
  'Ambient',
  'Crunk',
  'Downtempo',
  'Electro',
  'Electronica',
  'Electronic Rock',
  'IDM/Experimental',
  'Industrial',
  'Alternative Rap',
  'Bounce',
  'Dirty South',
  'East Coast Rap',
  'Gangsta Rap',
  'Hardcore Rap',
  'Hip-Hop',
  'Latin Rap',
  'Old School Rap',
  'Rap',
  'Underground Rap',
  'West Coast Rap',
  'Chanukah',
  'Christmas',
  'Christmas: Children’s',
  'Christmas: Classic',
  'Christmas: Classical',
  'Christmas: Jazz',
  'Christmas: Modern',
  'Christmas: Pop',
  'Christmas: R&B',
  'Christmas: Religious',
  'Christmas: Rock',
  'Easter',
  'Halloween',
  'Holiday: Other',
  'Thanksgiving',
  'CCM',
  'Christian Metal',
  'Christian Pop',
  'Christian Rap',
  'Christian Rock',
  'Classic Christian',
  'Contemporary Gospel',
  'Gospel',
  'Christian & Gospel',
  'Praise & Worship',
  'Qawwali',
  'Southern Gospel',
  'Traditional Gospel',
  'March (Marching Band)',
  'Karaoke',
  'Acid Jazz',
  'Avant-Garde Jazz',
  'Big Band',
  'Blue Note',
  'Contemporary Jazz',
  'Cool',
  'Crossover Jazz',
  'Dixieland',
  'Ethio-jazz',
  'Fusion',
  'Hard Bop',
  'Latin Jazz',
  'Mainstream Jazz',
  'Ragtime',
  'Smooth Jazz',
  'Trad Jazz',
  'Alternativo & Rock Latino',
  'Baladas y Boleros',
  'Brazilian',
  'Contemporary Latin',
  'Latin Jazz',
  'Pop Latino',
  'Raíces',
  'Reggaeton y Hip-Hop',
  'Regional Mexicano',
  'Salsa y Tropical',
  'Environmental',
  'Healing',
  'Meditation',
  'Nature',
  'Relaxation',
  'Travel',
  'Adult Contemporary',
  'Britpop',
  'Pop/Rock',
  'Soft Rock',
  'Teen Pop',
  'Indie Pop',
  'Anime',
  'K-Pop',
  'J-Pop',
  'French Pop',
  'German Pop',
  'Contemporary R&B',
  'Disco',
  'Doo Wop',
  'Funk',
  'Motown',
  'Neo-Soul',
  'Quiet Storm',
  'Soul',
  'Dancehall',
  'Dub',
  'Roots Reggae',
  'Ska',
  'Adult Alternative',
  'American Trad Rock',
  'Arena Rock',
  'Blues-Rock',
  'British Invasion',
  'Death Metal/Black Metal',
  'Glam Rock',
  'Hair Metal',
  'Hard Rock',
  'Metal',
  'Jam Bands',
  'Prog-Rock/Art Rock',
  'Psychedelic',
  'Rock & Roll',
  'Rockabilly',
  'Roots Rock',
  'Singer/Songwriter',
  'Southern Rock',
  'Surf',
  'Tex-Mex',
  'Alternative Folk',
  'Contemporary Folk',
  'Contemporary Singer/Songwriter',
  'Folk-Rock',
  'New Acoustic',
  'Traditional Folk',
  'German Folk',
  'Foreign Cinema',
  'Musicals',
  'Original Score',
  'Soundtrack',
  'TV Soundtrack',
  'Chicano',
  'Classic',
  'Conjunto',
  'Conjunto Progressive',
  'New Mex',
  'Tex-Mex',
  'Barbershop',
  'Doo-wop',
  'Standards',
  'Traditional Pop',
  'Vocal Jazz',
  'Vocal Pop',
  'Enka',
  'Sea Shanty',
  'African Heavy Metal',
  'African Hip Hop',
  'Afro-Beat',
  'Afro-House',
  'Afro-Pop',
  'Apala/Akpala',
  'Benga',
  'Bikutsi',
  'Bongo Flava',
  'Cape Jazz',
  'Chimurenga',
  'Coupé-Décalé',
  'Fuji Music',
  'Genge',
  'Gnawa',
  'Highlife',
  'Hiplife',
  'Isicathamiya',
  'Jit',
  'Jùjú',
  'Kapuka',
  'Kizomba',
  'Kuduro',
  'Kwaito',
  'Kwela',
  'Lingala',
  'Makossa',
  'Maloya',
  'Marrabenta',
  'Mbalax',
  'Mbaqanga',
  'Mbube',
  'Morna',
  'Museve',
  'Negro Spiritual',
  'Palm-Wine',
  'Raï',
  'Sakara',
  'Sega',
  'Seggae',
  'Semba',
  'Soukous',
  'Taarab',
  'Zouglou',
  'Anison',
  'Baithak Gana',
  'C-Pop',
  'CityPop',
  'Cantopop',
  'Enka',
  'Hong Kong English Pop',
  'Fann At-Tanbura',
  'Fijiri',
  'Khaliji',
  'Kayōkyoku',
  'Liwa',
  'Mandopop',
  'Onkyokei',
  'Taiwanese Pop',
  'Thai Pop',
  'Sawt',
  'Cajun',
  'Calypso',
  'Chutney',
  'Chutney Soca',
  'Compas',
  'Mambo',
  'Merengue',
  'Méringue',
  'Carnatic (Karnataka Sanghetha)',
  'Celtic',
  'Celtic Folk',
  'Contemporary Celtic',
  'Coupé-décalé – Congo',
  'Dangdut',
  'Drinking Songs',
  'Drone',
  'Klezmer',
  'Mbalax – Senegal',
  'Polka',
  'Soca',
  'Baila',
  'Bhangra',
  'Bhojpuri',
  'Dangdut',
  'Filmi',
  'Indian Pop',
  'Hindustani',
  'Indian Ghazal',
  'Lavani',
  'Luk Thung',
  'Luk Krung',
  'Manila Sound',
  'Morlam',
  'Pinoy Pop',
  'Pop Sunda',
  'Ragini',
  'Thai Pop',
  'Traditional Celtic',
  'Worldbeat',
  'Zydeco'
];

export const GROUPED_CATEGORIES = APPLE_CATEGORIES.reduce(
  (acc: { title: string; group: string }[], cat) => [
    ...acc,
    ...cat.map(c => ({ group: cat[0], title: c }))
  ],
  []
);

export const GROUPED_MUSIC_CATEGORIES = MUSIC_CATEGORIES.map(cat => ({
  title: cat,
  group: 'Music'
}));

export const CURRENCY_SYMBOLS: Record<CurrencyUnit, string> = {
  [CurrencyUnit.USD]: '$',
  [CurrencyUnit.GBP]: '£',
  [CurrencyUnit.EUR]: '€',
  [CurrencyUnit.AUD]: 'A$',
  [CurrencyUnit.SEK]: 'kr'
};

export const ROLES = Object.values(PersonRole);

export const GROUPS = [
  'Administration',
  'Artist',
  'Audio Post-Production',
  'Audio Production',
  'Band',
  'Cast',
  'Creative Direction',
  'Founders',
  'Marketing and Community',
  'Misc.',
  'Video Post-Production',
  'Video Production',
  'Visuals',
  'Writing'
];

export const ROLES_OPTIONS = ROLES.map(r => ({ label: r, value: r }));
export const MEDIA_TYPE_OPTIONS = Object.values(MediaMimeType).map(m => ({ value: m, label: m }));
export const GROUPS_OPTIONS = GROUPS.map(g => ({ label: g, value: g }));
export const LIVE_STATUS_OPTIONS = Object.values(LiveItemStatus).map(g => ({ label: g, value: g }));

export enum SocketEvents {
  AUTH = 'authenticate',
  PLAYED = 'played',
  PODCAST_UPDATE = 'podcasts-updated',
  NOTIFICATION = 'notification',
  BALANCE_LOW = 'balance-low',
  FLAGGED = 'flagged'
}

export enum PodcastSortValues {
  LastBuildDate = '-updatedAt',
  CreatedAt = '-createdAt'
}

export enum UserSortValues {
  LastLogin = '-lastLoginDate',
  CreatedAt = '-createdAt',
  LastPlayed = '-lastPlayed',
  Name = 'name'
}

export enum SuggestionSortValues {
  LastUpdate = '-updatedAt',
  Votes = 'votes'
}

export enum WaitListSortValues {
  CreatedAt = '-createdAt',
  Name = 'name'
}

export const PRODUCERS_SORT_OPTIONS = [
  {
    label: 'Largest Slate',
    value: '1'
  },
  {
    label: 'Last Updated',
    value: '3'
  },
  {
    label: 'Alphabetical',
    value: '2'
  }
];

export const SUGGESTION_SORT_OPTIONS = [
  {
    label: 'Recently Updated',
    value: SuggestionSortValues.LastUpdate
  },
  {
    label: 'Votes Count',
    value: SuggestionSortValues.Votes
  }
];

export const USERS_SORT_OPTIONS = [
  {
    label: 'Last Login',
    value: UserSortValues.LastLogin
  },
  {
    label: 'Created At',
    value: UserSortValues.CreatedAt
  },
  {
    label: 'Name',
    value: UserSortValues.Name
  }
];

export const WAIT_LIST_SORT_OPTIONS = [
  {
    label: 'Date Added',
    value: WaitListSortValues.CreatedAt
  },
  {
    label: 'Name',
    value: WaitListSortValues.Name
  }
];

export const PODCASTS_SORT_OPTIONS = [
  {
    label: 'Last Update Date',
    value: PodcastSortValues.LastBuildDate
  },
  {
    label: 'Created At',
    value: PodcastSortValues.CreatedAt
  }
];

export const HOST_SORT_OPTIONS = [
  {
    label: 'Tags',
    value: 'tags'
  },
  {
    label: 'Podcasts',
    value: 'podcasts'
  },
  {
    label: 'Failed Podcasts',
    value: 'failed'
  },
  {
    label: 'PSP Certified',
    value: 'psp'
  }
];

export const BOOL_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Yes',
    value: 'true'
  },
  {
    label: 'No',
    value: 'false'
  }
];

export const POD_STATUS_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  },
  {
    label: 'Failed',
    value: 'failed'
  },
  {
    label: 'Completed',
    value: 'completed'
  }
];

export const CLIP_TYPE_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Clip',
    value: ClipType.CLIP
  },
  {
    label: 'Soundbite',
    value: ClipType.SOUNDBITE
  }
];

export const ERR_TAG_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  ...Object.values(LogTag).map(t => ({ value: t, label: t }))
];

export const MEDIUM_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  ...Object.values(Medium).map(t => ({ value: t, label: t }))
];

export const LOG_TYPE_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  ...Object.values(LogLevel).map(t => ({ value: t, label: t }))
];

export const FEEDBACK_STATUS_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  ...Object.values(FeedbackStatus).map(t => ({ value: t, label: t }))
];

export const USER_ROLES_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Admin',
    value: UserRole.ADMIN
  },
  {
    label: 'Creator',
    value: UserRole.CREATOR
  },
  {
    label: 'Fan',
    value: UserRole.FAN
  }
];

export const STATUS_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Flagged',
    value: '1'
  },
  {
    label: 'Not Flagged',
    value: '0'
  }
];

export const MONTH_OPTIONS = [
  { value: '', label: 'View All' },
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

export const WAIT_LIST_STATUS_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Waiting',
    value: WaitlistStatus.WAITING
  },
  {
    label: 'Invited',
    value: WaitlistStatus.INVITED
  },
  {
    label: 'User',
    value: WaitlistStatus.USER
  }
];

export const CLAIM_STATUS_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Transferred',
    value: ClaimStatus.TRANSFERRED
  },
  {
    label: 'Verified',
    value: ClaimStatus.VERIFIED
  },
  {
    label: 'Rejected',
    value: ClaimStatus.REJECTED
  },
  {
    label: 'Emailed',
    value: ClaimStatus.EMAILED
  }
];

export const REPORT_TYPE_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Explicit',
    value: ReportType.EXPLICIT
  },
  {
    label: 'AI',
    value: ReportType.AI
  },
  {
    label: 'Other',
    value: ReportType.OTHER
  }
];

export const CLIPS_DURATIONS = Array.from({ length: 24 }).map((_, i) => ({
  label: `${getDurationLabel((i + 1) * 5)}`,
  value: `${(i + 1) * 5}`
}));

export const LICENSE_OPTIONS = [
  {
    label: 'All Rights Reserved',
    value: 'all rights reserved'
  },
  {
    label: 'Creative Commons Attribution 4.0 International',
    value: 'cc-by-4.0',
    url: 'https://creativecommons.org/licenses/by/4.0/legalcode.en'
  },
  {
    label: 'Creative Commons Attribution Share Alike 4.0 International',
    value: 'cc-by-sa-4.0',
    url: 'https://creativecommons.org/licenses/by-sa/4.0/legalcode.en'
  },
  {
    label: 'Creative Commons Attribution Non Commercial 4.0 International',
    value: 'cc-by-nc-4.0',
    url: 'https://creativecommons.org/licenses/by-nc/4.0/legalcode.en'
  },
  {
    label: 'Creative Commons Attribution Non Commercial Share Alike 4.0 International',
    value: 'cc-by-nc-sa-4.0',
    url: 'https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode.en'
  },
  {
    label: 'Creative Commons Attribution No Derivatives 4.0 International',
    value: 'cc-by-nd-4.0',
    url: 'https://creativecommons.org/licenses/by-nd/4.0/legalcode.en'
  },
  {
    label: 'Creative Commons Attribution Non Commercial No Derivatives 4.0 International',
    value: 'cc-by-nc-nd-4.0',
    url: 'https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode.en'
  },
  {
    label: 'Creative Commons No Copyright Universal',
    value: 'cc0-1.0',
    url: 'https://creativecommons.org/publicdomain/zero/1.0/deed.en'
  },
  {
    label: 'Podcast License',
    value: 'v4v-license-v-4.0',
    url: 'https://github.com/Podcastindex-org/podcast-namespace/blob/main/license/v4v-4.0.md'
  }
];

export const MUSIC_LICENSE_OPTIONS = LICENSE_OPTIONS.slice(0, -1).concat({
  label: 'Music License',
  value: 'v4v-license-v-4.0',
  url: 'https://github.com/Podcastindex-org/podcast-namespace/blob/main/license/v4v-4.0.md'
});

export const HOSTING_OPTIONS = [
  {
    value: '65859484c7298eddaf78ea64',
    label: '3speak.tv'
  },
  {
    value: '65859d7ad9a765885d8bd09a',
    label: 'Acast'
  },
  {
    value: '65859fc3d9a765885d8bd190',
    label: 'Apple Podcasts'
  },
  {
    value: '65859f95d9a765885d8bd17e',
    label: 'Art19'
  },
  {
    value: '65859dd7d9a765885d8bd0a6',
    label: 'Audioboom'
  },
  {
    value: '65859dfad9a765885d8bd0b2',
    label: 'Ausha'
  },
  {
    value: '65859e24d9a765885d8bd0c4',
    label: 'Blog Talk Radio'
  },
  {
    value: '65859484c7298eddaf78ea65',
    label: 'Blubrry'
  },
  {
    value: '65859484c7298eddaf78ea66',
    label: 'Buzzsprout'
  },
  {
    value: '65859e51d9a765885d8bd0df',
    label: 'Callin'
  },
  {
    value: '65859484c7298eddaf78ea67',
    label: 'Caproni'
  },
  {
    value: '65859484c7298eddaf78ea68',
    label: 'Captivate'
  },
  {
    value: '65859484c7298eddaf78ea69',
    label: 'Castgarden'
  },
  {
    value: '65859484c7298eddaf78ea6a',
    label: 'Castopod'
  },
  {
    value: '65859484c7298eddaf78ea6b',
    label: 'Castos'
  },
  {
    value: '65859e94d9a765885d8bd105',
    label: 'Cohost'
  },
  {
    value: '65859484c7298eddaf78ea6c',
    label: 'Disctopia'
  },
  {
    value: '65859484c7298eddaf78ea6d',
    label: 'Fireside'
  },
  {
    value: '65859484c7298eddaf78ea6e',
    label: 'Hubhopper'
  },
  {
    value: '65859484c7298eddaf78ea6f',
    label: 'IPFS Podcasting'
  },
  {
    value: '65859eacd9a765885d8bd111',
    label: 'Ivoox'
  },
  {
    value: '65859ec3d9a765885d8bd11d',
    label: 'Julep'
  },
  {
    value: '65859484c7298eddaf78ea70',
    label: 'Justcast'
  },
  {
    value: '65859484c7298eddaf78ea71',
    label: 'Libsyn'
  },
  {
    value: '65859484c7298eddaf78ea7c',
    label: 'Megaphone'
  },
  {
    value: '65859484c7298eddaf78ea72',
    label: 'Musicsideproject'
  },
  {
    value: '65859484c7298eddaf78ea73',
    label: 'Myweblog'
  },
  {
    value: '65859ee9d9a765885d8bd131',
    label: 'Omny Studio'
  },
  {
    value: '65859484c7298eddaf78ea74',
    label: 'Peertube'
  },
  {
    value: '65859484c7298eddaf78ea75',
    label: 'Podbean'
  },
  {
    value: '65859484c7298eddaf78ea76',
    label: 'Podcloud'
  },
  {
    value: '65859484c7298eddaf78ea7d',
    label: 'Podhome'
  },
  {
    value: '65859484c7298eddaf78ea77',
    label: 'Podserve'
  },
  {
    value: '665e45c01585733b182399bb',
    label: 'PodToo'
  },
  {
    value: '65859484c7298eddaf78ea78',
    label: 'Podvine'
  },
  {
    value: '65859484c7298eddaf78ea79',
    label: 'Power press by blubrry'
  },
  {
    value: '65859484c7298eddaf78ea7a',
    label: 'Redcircle'
  },
  {
    value: '65859484c7298eddaf78ea7e',
    label: 'Rss.com'
  },
  {
    value: '65859484c7298eddaf78ea7b',
    label: 'RSSblue'
  },
  {
    value: '65ae98cb1694d9726377de80',
    label: 'Self'
  },
  {
    value: '65859484c7298eddaf78ea7f',
    label: 'Simplecast'
  },
  {
    value: '65859484c7298eddaf78ea80',
    label: 'Sovereignfeeds'
  },
  {
    value: '65859484c7298eddaf78ea81',
    label: 'Spotify'
  },
  {
    value: '65859484c7298eddaf78ea82',
    label: 'Spreaker'
  },
  {
    value: '65859f34d9a765885d8bd14e',
    label: 'Storyboard'
  },
  {
    value: '65859f49d9a765885d8bd15a',
    label: 'Substack'
  },
  {
    value: '65859484c7298eddaf78ea83',
    label: 'Transistor'
  },
  {
    value: '65859f61d9a765885d8bd169',
    label: 'Triton Digital'
  },
  {
    value: '65859484c7298eddaf78ea84',
    label: 'Vodio'
  },
  {
    value: '65859f78d9a765885d8bd172',
    label: 'Voxnest'
  },
  {
    value: '65859484c7298eddaf78ea85',
    label: 'Wavlake'
  }
];

export const HOST_TAGS = Object.values(HostTag);

export const LANGUAGE_OPTIONS = [
  {
    value: 'en-gb',
    label: 'British English'
  },
  {
    value: 'en-us',
    label: 'American English'
  },
  {
    value: 'en-au',
    label: 'Australian English'
  },
  {
    value: 'aa',
    label: 'aa'
  },
  {
    value: 'ab',
    label: 'ab'
  },
  {
    value: 'ae',
    label: 'ae'
  },
  {
    value: 'af',
    label: 'Afrikaans'
  },
  {
    value: 'ak',
    label: 'Akan'
  },
  {
    value: 'tw',
    label: 'Akan'
  },
  {
    value: 'sq',
    label: 'Albanian'
  },
  {
    value: 'am',
    label: 'Amharic'
  },
  {
    value: 'ar',
    label: 'Arabic'
  },
  {
    value: 'an',
    label: 'Aragonese'
  },
  {
    value: 'hy',
    label: 'Armenian'
  },
  {
    value: 'as',
    label: 'Assamese'
  },
  {
    value: 'av',
    label: 'av'
  },
  {
    value: 'ay',
    label: 'Aymara'
  },
  {
    value: 'az',
    label: 'Azerbaijani'
  },
  {
    value: 'ba',
    label: 'ba'
  },
  {
    value: 'bm',
    label: 'Bambara'
  },
  {
    value: 'bn',
    label: 'Bangla'
  },
  {
    value: 'eu',
    label: 'Basque'
  },
  {
    value: 'be',
    label: 'Belarusian'
  },
  {
    value: 'bi',
    label: 'bi'
  },
  {
    value: 'bo',
    label: 'bo'
  },
  {
    value: 'bs',
    label: 'Bosnian'
  },
  {
    value: 'br',
    label: 'Breton'
  },
  {
    value: 'bg',
    label: 'Bulgarian'
  },
  {
    value: 'my',
    label: 'Burmese'
  },
  {
    value: 'en-ca',
    label: 'Canadian English'
  },
  {
    value: 'ca',
    label: 'Catalan'
  },
  {
    value: 'ce',
    label: 'ce'
  },
  {
    value: 'ch',
    label: 'ch'
  },
  {
    value: 'zh',
    label: 'Chinese'
  },
  {
    value: 'co',
    label: 'Corsican'
  },
  {
    value: 'cr',
    label: 'cr'
  },
  {
    value: 'hr',
    label: 'Croatian'
  },
  {
    value: 'cu',
    label: 'cu'
  },
  {
    value: 'cv',
    label: 'cv'
  },
  {
    value: 'cs',
    label: 'Czech'
  },
  {
    value: 'da',
    label: 'Danish'
  },
  {
    value: 'dv',
    label: 'Divehi'
  },
  {
    value: 'nl',
    label: 'Dutch'
  },
  {
    value: 'dz',
    label: 'dz'
  },
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'eo',
    label: 'Esperanto'
  },
  {
    value: 'et',
    label: 'Estonian'
  },
  {
    value: 'ee',
    label: 'Ewe'
  },
  {
    value: 'fo',
    label: 'Faroese'
  },
  {
    value: 'ff',
    label: 'ff'
  },
  {
    value: 'tl',
    label: 'Filipino'
  },
  {
    value: 'fi',
    label: 'Finnish'
  },
  {
    value: 'fj',
    label: 'fj'
  },
  {
    value: 'fr',
    label: 'French'
  },
  {
    value: 'gl',
    label: 'Galician'
  },
  {
    value: 'lg',
    label: 'Ganda'
  },
  {
    value: 'ka',
    label: 'Georgian'
  },
  {
    value: 'de',
    label: 'German'
  },
  {
    value: 'el',
    label: 'Greek'
  },
  {
    value: 'gn',
    label: 'Guarani'
  },
  {
    value: 'gu',
    label: 'Gujarati'
  },
  {
    value: 'gv',
    label: 'gv'
  },
  {
    value: 'ht',
    label: 'Haitian Creole'
  },
  {
    value: 'ha',
    label: 'Hausa'
  },
  {
    value: 'he',
    label: 'Hebrew'
  },
  {
    value: 'hi',
    label: 'Hindi'
  },
  {
    value: 'ho',
    label: 'ho'
  },
  {
    value: 'hu',
    label: 'Hungarian'
  },
  {
    value: 'hz',
    label: 'hz'
  },
  {
    value: 'is',
    label: 'Icelandic'
  },
  {
    value: 'ie',
    label: 'ie'
  },
  {
    value: 'ig',
    label: 'Igbo'
  },
  {
    value: 'ii',
    label: 'ii'
  },
  {
    value: 'ik',
    label: 'ik'
  },
  {
    value: 'id',
    label: 'Indonesian'
  },
  {
    value: 'ia',
    label: 'Interlingua'
  },
  {
    value: 'io',
    label: 'io'
  },
  {
    value: 'ga',
    label: 'Irish'
  },
  {
    value: 'it',
    label: 'Italian'
  },
  {
    value: 'iu',
    label: 'iu'
  },
  {
    value: 'ja',
    label: 'Japanese'
  },
  {
    value: 'jv',
    label: 'Javanese'
  },
  {
    value: 'kn',
    label: 'Kannada'
  },
  {
    value: 'kk',
    label: 'Kazakh'
  },
  {
    value: 'kg',
    label: 'kg'
  },
  {
    value: 'km',
    label: 'Khmer'
  },
  {
    value: 'ki',
    label: 'ki'
  },
  {
    value: 'rw',
    label: 'Kinyarwanda'
  },
  {
    value: 'kj',
    label: 'kj'
  },
  {
    value: 'kl',
    label: 'kl'
  },
  {
    value: 'ko',
    label: 'Korean'
  },
  {
    value: 'kr',
    label: 'kr'
  },
  {
    value: 'ks',
    label: 'ks'
  },
  {
    value: 'ku',
    label: 'Kurdish'
  },
  {
    value: 'kv',
    label: 'kv'
  },
  {
    value: 'kw',
    label: 'kw'
  },
  {
    value: 'ky',
    label: 'Kyrgyz'
  },
  {
    value: 'lo',
    label: 'Lao'
  },
  {
    value: 'la',
    label: 'Latin'
  },
  {
    value: 'lv',
    label: 'Latvian'
  },
  {
    value: 'li',
    label: 'li'
  },
  {
    value: 'ln',
    label: 'Lingala'
  },
  {
    value: 'lt',
    label: 'Lithuanian'
  },
  {
    value: 'lu',
    label: 'lu'
  },
  {
    value: 'lb',
    label: 'Luxembourgish'
  },
  {
    value: 'mk',
    label: 'Macedonian'
  },
  {
    value: 'mg',
    label: 'Malagasy'
  },
  {
    value: 'ms',
    label: 'Malay'
  },
  {
    value: 'ml',
    label: 'Malayalam'
  },
  {
    value: 'mt',
    label: 'Maltese'
  },
  {
    value: 'mi',
    label: 'Māori'
  },
  {
    value: 'mr',
    label: 'Marathi'
  },
  {
    value: 'es-mx',
    label: 'Mexican Spanish'
  },
  {
    value: 'mh',
    label: 'mh'
  },
  {
    value: 'mn',
    label: 'Mongolian'
  },
  {
    value: 'na',
    label: 'na'
  },
  {
    value: 'nd',
    label: 'nd'
  },
  {
    value: 'ne',
    label: 'Nepali'
  },
  {
    value: 'ng',
    label: 'ng'
  },
  {
    value: 'no',
    label: 'Norwegian'
  },
  {
    value: 'nb',
    label: 'Norwegian Bokmål'
  },
  {
    value: 'nn',
    label: 'Norwegian Nynorsk'
  },
  {
    value: 'nr',
    label: 'nr'
  },
  {
    value: 'nv',
    label: 'nv'
  },
  {
    value: 'ny',
    label: 'Nyanja'
  },
  {
    value: 'oc',
    label: 'Occitan'
  },
  {
    value: 'or',
    label: 'Odia'
  },
  {
    value: 'oj',
    label: 'oj'
  },
  {
    value: 'om',
    label: 'Oromo'
  },
  {
    value: 'os',
    label: 'os'
  },
  {
    value: 'ps',
    label: 'Pashto'
  },
  {
    value: 'fa',
    label: 'Persian'
  },
  {
    value: 'pi',
    label: 'pi'
  },
  {
    value: 'pl',
    label: 'Polish'
  },
  {
    value: 'pt',
    label: 'Portuguese'
  },
  {
    value: 'pa',
    label: 'Punjabi'
  },
  {
    value: 'qu',
    label: 'Quechua'
  },
  {
    value: 'rn',
    label: 'rn'
  },
  {
    value: 'ro',
    label: 'Romanian'
  },
  {
    value: 'rm',
    label: 'Romansh'
  },
  {
    value: 'ru',
    label: 'Russian'
  },
  {
    value: 'sm',
    label: 'Samoan'
  },
  {
    value: 'sa',
    label: 'Sanskrit'
  },
  {
    value: 'sc',
    label: 'sc'
  },
  {
    value: 'gd',
    label: 'Scottish Gaelic'
  },
  {
    value: 'se',
    label: 'se'
  },
  {
    value: 'sr',
    label: 'Serbian'
  },
  {
    value: 'sg',
    label: 'sg'
  },
  {
    value: 'sn',
    label: 'Shona'
  },
  {
    value: 'sd',
    label: 'Sindhi'
  },
  {
    value: 'si',
    label: 'Sinhala'
  },
  {
    value: 'sk',
    label: 'Slovak'
  },
  {
    value: 'sl',
    label: 'Slovenian'
  },
  {
    value: 'so',
    label: 'Somali'
  },
  {
    value: 'st',
    label: 'Southern Sotho'
  },
  {
    value: 'es',
    label: 'Spanish'
  },
  {
    value: 'ss',
    label: 'ss'
  },
  {
    value: 'su',
    label: 'Sundanese'
  },
  {
    value: 'sw',
    label: 'Swahili'
  },
  {
    value: 'sv',
    label: 'Swedish'
  },
  {
    value: 'tg',
    label: 'Tajik'
  },
  {
    value: 'ta',
    label: 'Tamil'
  },
  {
    value: 'tt',
    label: 'Tatar'
  },
  {
    value: 'te',
    label: 'Telugu'
  },
  {
    value: 'th',
    label: 'Thai'
  },
  {
    value: 'ti',
    label: 'Tigrinya'
  },
  {
    value: 'to',
    label: 'Tongan'
  },
  {
    value: 'ts',
    label: 'Tsonga'
  },
  {
    value: 'tn',
    label: 'Tswana'
  },
  {
    value: 'tr',
    label: 'Turkish'
  },
  {
    value: 'tk',
    label: 'Turkmen'
  },
  {
    value: 'ty',
    label: 'ty'
  },
  {
    value: 'uk',
    label: 'Ukrainian'
  },
  {
    value: 'ur',
    label: 'Urdu'
  },
  {
    value: 'ug',
    label: 'Uyghur'
  },
  {
    value: 'uz',
    label: 'Uzbek'
  },
  {
    value: 've',
    label: 've'
  },
  {
    value: 'vi',
    label: 'Vietnamese'
  },
  {
    value: 'vo',
    label: 'vo'
  },
  {
    value: 'wa',
    label: 'Walloon'
  },
  {
    value: 'cy',
    label: 'Welsh'
  },
  {
    value: 'fy',
    label: 'Western Frisian'
  },
  {
    value: 'wo',
    label: 'Wolof'
  },
  {
    value: 'xh',
    label: 'Xhosa'
  },
  {
    value: 'yi',
    label: 'Yiddish'
  },
  {
    value: 'yo',
    label: 'Yoruba'
  },
  {
    value: 'za',
    label: 'za'
  },
  {
    value: 'zu',
    label: 'Zulu'
  }
];

export const COUNTRIES_MAP = {
  GB: 'United Kingdom',
  US: 'United States of America',
  AF: 'Afghanistan',
  AX: 'Åland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo (the Democratic Republic of the)',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Côte d'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Laos',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MK: 'North Macedonia',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
};

export type CountryCodeType = keyof typeof COUNTRIES_MAP;

export const COUNTRIES_CODE = Object.keys(COUNTRIES_MAP) as CountryCodeType[];

export const DEFAULT_PEAKS = [
  0.0, 0.0, -0.31, 0.32, -0.31, 0.31, -0.28, 0.31, -0.24, 0.24, -0.28, 0.23, -0.24, 0.28, -0.28,
  0.28, -0.18, 0.15, -0.22, 0.28, -0.3, 0.21, -0.19, 0.24, -0.18, 0.16, -0.13, 0.12, -0.15, 0.17,
  -0.19, 0.17, -0.17, 0.16, -0.21, 0.19, -0.23, 0.45, -0.61, 0.76, -0.59, 0.74, -0.19, 0.38, -0.31,
  0.33, -0.49, 0.54, -0.45, 0.48, -0.45, 0.55, -0.41, 0.43, -0.14, 0.21, -0.24, 0.3, -0.43, 0.46,
  -0.4, 0.41, -0.2, 0.21, -0.53, 0.57, -0.61, 0.76, -0.41, 0.61, -0.06, 0.06, -0.29, 0.42, -0.29,
  0.4, -0.16, 0.17, -0.23, 0.25, -0.19, 0.26, -0.1, 0.1, -0.08, 0.09, -0.17, 0.18, -0.25, 0.35,
  -0.23, 0.41, -0.28, 0.39, -0.07, 0.1, -0.13, 0.16, -0.31, 0.35, -0.42, 0.39, -0.32, 0.38, -0.19,
  0.26, -0.07, 0.06, -0.05, 0.04, -0.04, 0.03, -0.02, 0.03, -0.19, 0.2, -0.13, 0.2, -0.09, 0.11,
  -0.14, 0.17, -0.03, 0.04, -0.02, 0.05, -0.49, 0.48, -0.46, 0.43, -0.33, 0.51, -0.33, 0.38, -0.31,
  0.46, -0.35, 0.35, -0.35, 0.28, -0.58, 0.54, -0.52, 0.76, -0.37, 0.57, -0.13, 0.13, -0.35, 0.41,
  -0.45, 0.46, -0.47, 0.34, -0.17, 0.21, -0.16, 0.21, -0.31, 0.38, -0.5, 0.48, -0.48, 0.42, -0.35,
  0.27, -0.57, 0.63, -0.39, 0.51, -0.31, 0.48, -0.28, 0.19, -0.54, 0.38, -0.35, 0.49, -0.4, 0.5,
  -0.29, 0.25, -0.26, 0.27, -0.43, 0.65, -0.4, 0.5, -0.24, 0.22, -0.28, 0.28, -0.34, 0.31, -0.18,
  0.13, -0.22, 0.21, -0.17, 0.17, -0.2, 0.2, -0.43, 0.54, -0.57, 0.65, -0.43, 0.49, -0.2, 0.2,
  -0.16, 0.23, -0.36, 0.47, -0.35, 0.46, -0.48, 0.35, -0.26, 0.54, -0.24, 0.19, -0.11, 0.14, -0.06,
  0.09, -0.08, 0.09, -0.07, 0.07, -0.04, 0.04, -0.1, 0.1, -0.38, 0.46, -0.39, 0.54, -0.16, 0.3,
  -0.08, 0.1, -0.65, 0.97, -0.74, 0.76, -0.46, 0.48, -0.76, 0.55, -0.64, 0.61, -0.39, 0.32, -0.61,
  0.51, -0.54, 0.43, -0.28, 0.29, -0.22, 0.28, -0.32, 0.5, -0.49, 0.31, -0.81, 0.58, -0.72, 0.57,
  -0.24, 0.24, -0.38, 0.32, -0.46, 0.52, -0.46, 0.47, -0.2, 0.25, -0.43, 0.43, -0.3, 0.29, -0.25,
  0.28, -0.53, 0.59, -0.51, 0.67, -0.17, 0.34, -0.2, 0.2, -0.1, 0.14, -0.5, 0.54, -0.4, 0.55, -0.47,
  0.46, -0.33, 0.42, -0.29, 0.14, -0.54, 0.6, -0.72, 0.78, -0.59, 0.66, -0.3, 0.38, -0.31, 0.39,
  -0.66, 0.62, -0.62, 0.58, -0.16, 0.31, -0.16, 0.13, -0.09, 0.11, -0.01, 0.01, -0.31, 0.33, -0.39,
  0.39, -0.5, 0.66, -0.46, 0.52, -0.18, 0.2, -0.14, 0.19, -0.55, 0.67, -0.54, 0.66, -0.51, 0.56,
  -0.37, 0.53, -0.32, 0.35, -0.33, 0.35, -0.5, 0.53, -0.65, 0.56, -0.31, 0.35, -0.31, 0.32, -0.49,
  0.36, -0.51, 0.59, -0.31, 0.41, -0.25, 0.2, -0.61, 0.39, -0.62, 0.35, -0.34, 0.39, -0.23, 0.28,
  -0.62, 0.8, -0.72, 0.73, -0.35, 0.41, -0.29, 0.21, -0.17, 0.18, -0.13, 0.1, -0.96, 0.84, -0.87,
  0.91, -0.81, 0.76, -0.19, 0.24, -0.09, 0.07, -0.54, 0.55, -0.61, 0.51, -0.57, 0.39, -0.27, 0.28,
  -0.1, 0.14, -0.49, 0.69, -0.57, 0.65, -0.57, 0.51, -0.57, 0.6, -0.29, 0.38, -0.39, 0.55, -0.31,
  0.47, -0.28, 0.39, -0.29, 0.5, -0.25, 0.34, -0.13, 0.12, -0.12, 0.11, -0.6, 0.69, -0.64, 0.54,
  -0.13, 0.16, -0.28, 0.33, -0.41, 0.67, -0.46, 0.71, -0.2, 0.3, -0.06, 0.06, -0.47, 0.76, -0.54,
  0.67, -0.41, 0.54, -0.4, 0.56, -0.23, 0.43, -0.17, 0.18, -0.39, 0.42, -0.6, 0.55, -0.46, 0.36,
  -0.2, 0.18, -0.41, 0.48, -0.37, 0.5, -0.23, 0.4, -0.25, 0.22, -0.09, 0.09, -0.18, 0.23, -0.83,
  0.61, -0.73, 0.43, -0.74, 0.49, -0.74, 0.35, -0.78, 0.39, -0.59, 0.25, -0.17, 0.06, -0.36, 0.39,
  -0.08, 0.06, -0.09, 0.1, -0.29, 0.53, -0.39, 0.46, -0.04, 0.03, -0.26, 0.25, -0.55, 0.42, -0.43,
  0.45, -0.39, 0.38, -0.32, 0.28, -0.5, 0.59, -0.45, 0.49, -0.33, 0.4, -0.31, 0.41, -0.2, 0.13,
  -0.2, 0.25, -0.33, 0.49, -0.4, 0.43, -0.31, 0.37, -0.11, 0.15, -0.03, 0.07, -0.04, 0.05, -0.04,
  0.03, -0.08, 0.07, -0.11, 0.09, -0.12, 0.18, -0.19, 0.19, -0.16, 0.16, -0.18, 0.13, -0.27, 0.26,
  -0.37, 0.26, -0.2, 0.25, -0.28, 0.15, -0.26, 0.22, -0.23, 0.2, -0.57, 0.43, -0.65, 0.72, -0.57,
  0.72, -0.56, 0.49, -0.42, 0.58, -0.57, 0.54, -0.65, 0.73, -0.56, 0.65, -0.52, 0.71, -0.03, 0.04,
  -0.21, 0.18, -0.46, 0.36, -0.43, 0.32, -0.54, 0.27, -0.13, 0.08, -0.65, 0.36, -0.54, 0.59, -0.24,
  0.31, -0.39, 0.16, -0.69, 0.64, -0.16, 0.2, -0.2, 0.24, -0.39, 0.47, -0.54, 0.65, -0.55, 0.64,
  -0.2, 0.13, -0.5, 0.48, -0.34, 0.3, -0.51, 0.3, -0.4, 0.37, -0.72, 0.62, -0.76, 0.94, -0.65, 0.85,
  -0.61, 0.63, -0.2, 0.16, -0.08, 0.09, -0.56, 0.32, -0.54, 0.29, -0.29, 0.15, -0.24, 0.12, -0.34,
  0.17, -0.35, 0.23, -0.46, 0.34, -0.4, 0.31, -0.33, 0.2, -0.29, 0.34, -0.28, 0.32, -0.26, 0.21,
  -0.21, 0.24, -0.46, 0.45, -0.49, 0.54, -0.72, 0.61, -0.44, 0.35, -0.06, 0.06, -0.4, 0.38, -0.51,
  0.51, -0.46, 0.51, -0.68, 0.75, -0.63, 0.72, -0.43, 0.41, -0.54, 0.53, -0.05, 0.06, -0.54, 0.32,
  -0.51, 0.28, -0.2, 0.18, -0.06, 0.1, -0.04, 0.04, -0.51, 0.59, -0.7, 0.67, -0.35, 0.2, -0.6, 0.42,
  -0.57, 0.43, -0.67, 0.53, -0.65, 0.68, -0.07, 0.06, -0.48, 0.28, -0.05, 0.07, -0.68, 0.54, -0.75,
  0.57, -0.4, 0.46, -0.31, 0.3, -0.65, 0.35, -0.57, 0.39, -0.35, 0.24, -0.23, 0.18, -0.23, 0.24,
  -0.29, 0.26, -0.39, 0.29, -0.17, 0.23, -0.13, 0.17, -0.48, 0.35, -0.24, 0.18, -0.66, 0.56, -0.83,
  0.91, -0.43, 0.45, -0.17, 0.14, -0.67, 0.54, -1.0, 0.93, -0.87, 0.98, -0.54, 0.55, -0.74, 0.59,
  -0.75, 0.54, -0.44, 0.64, -0.48, 0.51, -0.52, 0.5, -0.74, 0.81, -0.65, 0.96, -0.58, 0.92, -0.6,
  0.59, -0.27, 0.21, -0.37, 0.25, -0.4, 0.43, -0.35, 0.43, -0.28, 0.19, -0.32, 0.35, -0.22, 0.19,
  -0.14, 0.12, -0.42, 0.28, -0.17, 0.17, -0.13, 0.13, -0.13, 0.13, -0.1, 0.08, -0.06, 0.06, -0.11,
  0.13, -0.21, 0.32, -0.43, 0.4, -0.31, 0.2, -0.35, 0.39, -0.38, 0.41, -0.15, 0.15, -0.3, 0.28,
  -0.31, 0.3, -0.22, 0.15, -0.43, 0.39, -0.15, 0.13, -0.17, 0.2, -0.44, 0.52, -0.24, 0.4, -0.3,
  0.27, -0.28, 0.2, -0.16, 0.13, -0.31, 0.39, -0.28, 0.37, -0.24, 0.19, -0.17, 0.17, -0.06, 0.05,
  -0.2, 0.24, -0.27, 0.28, -0.43, 0.54, -0.3, 0.39, -0.22, 0.18, -0.17, 0.29, -0.3, 0.31, -0.56,
  0.68, -0.39, 0.43, -0.24, 0.26, -0.44, 0.43, -0.55, 0.65, -0.39, 0.39, -0.2, 0.2, -0.37, 0.47,
  -0.3, 0.27, -0.17, 0.25, -0.41, 0.39, -0.31, 0.45, -0.45, 0.47, -0.72, 0.55, -0.68, 0.72, -0.57,
  0.49, -0.31, 0.35, -0.57, 0.46, -0.43, 0.37, -0.43, 0.33, -0.2, 0.23, -0.31, 0.37, -0.46, 0.34,
  -0.14, 0.21, -0.11, 0.13, -0.11, 0.12, -0.32, 0.4, -0.17, 0.2, -0.45, 0.3, -0.46, 0.49, -0.5,
  0.37, -0.29, 0.26, -0.12, 0.16, -0.41, 0.3, -0.44, 0.34, -0.43, 0.41, -0.53, 0.47, -0.24, 0.21,
  -0.28, 0.28, -0.29, 0.18, -0.21, 0.19, -0.5, 0.39, -0.69, 0.6, -0.59, 0.56, -0.52, 0.49, -0.27,
  0.18, -0.29, 0.28, -0.56, 0.52, -0.39, 0.39, -0.16, 0.2, -0.09, 0.11, -0.29, 0.3, -0.3, 0.35,
  -0.29, 0.24, -0.39, 0.35, -0.3, 0.35, -0.63, 0.61, -0.8, 0.8, -0.3, 0.26, -0.06, 0.08, -0.09,
  0.06, -0.39, 0.32, -0.38, 0.41, -0.76, 0.65, -0.72, 0.72, -0.44, 0.4, -0.46, 0.46, -0.51, 0.57,
  -0.37, 0.27, -0.22, 0.21, -0.47, 0.49, -0.37, 0.48, -0.25, 0.27, -0.46, 0.46, -0.32, 0.39, -0.34,
  0.39, -0.69, 0.42, -0.46, 0.59, -0.51, 0.47, -0.35, 0.42, -0.12, 0.13, -0.46, 0.43, -0.8, 0.65,
  -0.81, 0.62, -0.28, 0.28, -0.13, 0.17, -0.59, 0.46, -0.66, 0.91, -0.6, 0.56, -0.57, 0.55, -0.81,
  0.75, -0.57, 0.62, -0.43, 0.3, -0.24, 0.17, -0.83, 0.65, -0.53, 0.65, -0.41, 0.57, -0.31, 0.29,
  -0.55, 0.6, -0.47, 0.43, -0.15, 0.24, -0.24, 0.22, -0.28, 0.25, -0.31, 0.31, -0.29, 0.28, -0.24,
  0.24, -0.16, 0.16, -0.34, 0.34, -0.28, 0.24, -0.15, 0.19, -0.64, 0.51, -0.24, 0.48, -0.37, 0.5,
  -0.8, 0.79, -0.69, 0.8, -0.65, 0.63, -0.69, 0.6, -0.71, 0.74, -0.72, 0.58, -0.51, 0.39, -0.11,
  0.17, -0.66, 0.65, -1.0, 0.7, -0.68, 0.58, -0.56, 0.76, -0.18, 0.19, -0.55, 0.39, -0.67, 0.65,
  -0.57, 0.56, -0.59, 0.67, -0.58, 0.48, -0.2, 0.17, -0.25, 0.2, -0.53, 0.43, -0.54, 0.5, -0.5,
  0.52, -0.34, 0.41, -0.42, 0.2, -0.4, 0.35, -0.24, 0.28, -0.08, 0.08, -0.48, 0.28, -0.43, 0.37,
  -0.57, 0.43, -0.35, 0.26, -0.37, 0.34, -0.51, 0.69, -0.53, 0.7, -0.53, 0.61, -0.39, 0.44, -0.76,
  0.53, -0.36, 0.34, -0.2, 0.23, -0.9, 0.82, -0.28, 0.3, -0.23, 0.24, -0.24, 0.27, -0.2, 0.2, -0.27,
  0.32, -0.24, 0.31, -0.22, 0.31, -0.21, 0.23, -0.15, 0.16, -0.18, 0.17, -0.18, 0.21, -0.27, 0.2,
  -0.19, 0.28, -0.2, 0.16, -0.17, 0.17, -0.27, 0.36, -0.28, 0.28, -0.17, 0.14, -0.2, 0.22, -0.26,
  0.45, -0.36, 0.57, -0.36, 0.38, -0.43, 0.6, -0.33, 0.45, -0.28, 0.34, -0.68, 0.71, -0.59, 0.78,
  -0.36, 0.67, -0.57, 0.83, -0.42, 0.69, -0.43, 0.76, -0.33, 0.68, -0.23, 0.24, -0.12, 0.09, -0.18,
  0.2, -0.25, 0.26, -0.41, 0.43, -0.47, 0.7, -0.36, 0.73, -0.27, 0.35, -0.2, 0.25, -0.28, 0.43,
  -0.02, 0.02, -0.47, 0.62, -0.41, 0.54, -0.37, 0.5, -0.41, 0.39, -0.09, 0.1, -0.32, 0.28, -0.13,
  0.18, -0.14, 0.11, -0.13, 0.09, -0.05, 0.05, -0.42, 0.33, -0.22, 0.2, -0.28, 0.28, -0.4, 0.4,
  -0.41, 0.5, -0.35, 0.43, -0.28, 0.32, -0.54, 0.49, -0.34, 0.63, -0.12, 0.16, -0.31, 0.47, -0.53,
  0.61, -0.44, 0.56, -0.35, 0.53, -0.32, 0.41, -0.09, 0.13, -0.43, 0.54, -0.7, 0.61, -0.5, 0.58,
  -0.35, 0.47, -0.37, 0.32, -0.19, 0.2, -0.13, 0.11, -0.22, 0.25, -0.03, 0.05, -0.01, 0.01, -0.88,
  0.81, -0.82, 0.87, -0.41, 0.57, -0.27, 0.47, -0.09, 0.09, -0.01, 0.01, 0.0, 0.0, -0.15, 0.14,
  -0.2, 0.23, -0.14, 0.21, -0.28, 0.52, -0.44, 0.66, -0.5, 0.73, -0.2, 0.21, -0.57, 0.49, -0.41,
  0.58, -0.2, 0.29, -0.34, 0.38, -0.24, 0.33, -0.43, 0.46, -0.46, 0.58, -0.15, 0.14, -0.14, 0.2,
  -0.5, 0.69, -0.33, 0.52, -0.2, 0.39, -0.3, 0.38, -0.27, 0.24, -0.21, 0.24, -0.39, 0.37, -0.28,
  0.38, -0.17, 0.24, -0.25, 0.24, -0.09, 0.08, -0.04, 0.04, -0.02, 0.02, -0.02, 0.02, -0.26, 0.32,
  -0.24, 0.32, -0.22, 0.25, -0.83, 0.63, -1.0, 0.83, -0.79, 0.81, -0.6, 0.43, -0.28, 0.35, -0.29,
  0.39, -0.29, 0.35, -0.25, 0.26, -0.31, 0.24, -0.22, 0.24, -0.05, 0.05, -0.25, 0.36, -0.28, 0.41,
  -0.2, 0.33, -0.17, 0.3, -0.23, 0.32, -0.13, 0.17, -0.35, 0.26, -0.16, 0.2, -0.04, 0.02, -0.14,
  0.2, -0.76, 0.65, -0.71, 0.6, -0.57, 0.69, -0.31, 0.43, -0.57, 0.69, -0.39, 0.54, -0.34, 0.23,
  -0.29, 0.5, -0.43, 0.39, -0.22, 0.39, -0.43, 0.61, -0.17, 0.2, -0.51, 0.54, -0.39, 0.59, -0.22,
  0.43, -0.2, 0.3, -0.17, 0.26, -0.24, 0.27, -0.2, 0.32, -0.24, 0.25, -0.24, 0.31, -0.31, 0.3,
  -0.43, 0.34, -0.24, 0.32, -0.35, 0.43, -0.24, 0.23, -0.17, 0.19, -0.38, 0.47, -0.2, 0.21, -0.41,
  0.49, -0.46, 0.57, -0.47, 0.5, -0.44, 0.54, -0.16, 0.22, -0.17, 0.13, -0.13, 0.13, -0.23, 0.36,
  -0.32, 0.35, -0.18, 0.18, -0.13, 0.16, -0.18, 0.24, -0.35, 0.42, -0.34, 0.38, -0.17, 0.11, -0.33,
  0.5, -0.4, 0.45, -0.19, 0.29, -0.16, 0.17, -0.32, 0.38, -0.24, 0.4, -0.19, 0.35, -0.13, 0.12,
  -0.39, 0.61, -0.4, 0.51, -0.29, 0.34, -0.31, 0.29, -0.29, 0.4, -0.35, 0.48, -0.25, 0.38, -0.15,
  0.17, -0.06, 0.06, -0.05, 0.04, -0.7, 0.43, -0.62, 0.41, -0.38, 0.32, -0.1, 0.1, -0.73, 0.62,
  -0.56, 0.46, -0.48, 0.54, -0.4, 0.57, -0.45, 0.41, -0.08, 0.09, -0.04, 0.05, -0.26, 0.36, -0.5,
  0.69, -0.53, 0.68, -0.79, 1.0, -0.93, 1.0, -0.59, 0.53, -1.0, 0.85, -1.0, 0.91, -0.24, 0.25,
  -0.46, 0.63, -0.35, 0.42, -0.29, 0.2, -0.24, 0.25, -0.47, 0.71, -0.63, 0.79, -0.62, 0.77, -0.21,
  0.13, -0.08, 0.08, -0.43, 0.54, -0.21, 0.29, -0.31, 0.35, -0.22, 0.3, -0.07, 0.08, -0.06, 0.08,
  -0.02, 0.02, -0.21, 0.24, -0.37, 0.56, -0.64, 0.57, -0.9, 0.77, -0.42, 0.25, -0.17, 0.2, -0.17,
  0.21, -0.2, 0.22, -0.16, 0.19, -0.35, 0.45, -0.28, 0.32, -0.6, 0.45, -0.51, 0.51, -0.15, 0.24,
  -0.26, 0.24, -0.17, 0.17, -0.27, 0.24, -0.3, 0.3, -0.34, 0.39, -0.36, 0.38, -0.42, 0.29, -0.2,
  0.16, -0.13, 0.09, -0.09, 0.09, -0.13, 0.13, -0.39, 0.52, -0.43, 0.57, -0.39, 0.31, -0.35, 0.29,
  -0.49, 0.72, -0.74, 0.69, -0.76, 0.75, -0.54, 0.39, -0.76, 0.66, -0.76, 0.74, -0.38, 0.22, -0.82,
  0.68, -0.75, 0.72, -0.73, 0.69, -0.67, 0.65, -0.29, 0.2, -0.66, 0.89, -0.5, 0.41, -0.11, 0.1,
  -0.42, 0.13, -0.54, 0.54, -0.46, 0.23, -0.57, 0.58, -0.52, 0.54, -0.54, 0.42, -0.18, 0.13, -0.51,
  0.57, -0.5, 0.63, -0.43, 0.32, -0.07, 0.09, -0.16, 0.14, -0.09, 0.09, -0.35, 0.23, -0.34, 0.2,
  -0.18, 0.2, -0.24, 0.35, -0.46, 0.67, -0.54, 0.76, -0.42, 0.57, -0.47, 0.39, -0.35, 0.38, -0.3,
  0.51, -0.43, 0.48, -0.32, 0.39, -0.18, 0.22, -0.14, 0.17, -0.3, 0.45, -0.35, 0.46, -0.26, 0.45,
  -0.23, 0.24, -0.3, 0.4, -0.35, 0.47, -0.24, 0.24, -0.24, 0.17, -0.17, 0.15, -0.46, 0.54, -0.34,
  0.57, -0.45, 0.62, -0.18, 0.13, -0.2, 0.19, -0.35, 0.39, -0.31, 0.48, -0.2, 0.44, -0.1, 0.09,
  -0.07, 0.12, -0.11, 0.2, -0.13, 0.19, -0.44, 0.45, -0.36, 0.31, -0.27, 0.23, -0.35, 0.21, -0.42,
  0.31, -0.24, 0.19, -0.1, 0.16, -0.17, 0.12, -0.28, 0.18, -0.42, 0.35, -0.51, 0.32, -0.54, 0.29,
  -0.31, 0.18, -0.49, 0.39, -0.56, 0.46, -0.57, 0.51, -0.5, 0.46, -0.42, 0.37, -0.48, 0.32, -0.38,
  0.38, -0.25, 0.2, -0.23, 0.29, -0.07, 0.08, -0.46, 0.45, -0.68, 0.76, -0.65, 0.9, -0.69, 0.61,
  -0.59, 0.39, -0.67, 0.62, -0.46, 0.44, -0.06, 0.06, -0.24, 0.33, -0.2, 0.17, -0.58, 0.47, -0.43,
  0.43, -0.47, 0.31, -0.45, 0.31, -0.09, 0.11, -0.09, 0.09, -0.06, 0.06, -0.05, 0.04, -0.32, 0.34,
  -0.2, 0.19, -0.48, 0.5, -0.61, 0.48, -0.86, 0.53, -0.68, 0.46, -0.4, 0.18, -0.91, 0.55, -0.94,
  0.57, -0.76, 0.35, -0.24, 0.21, -0.2, 0.23, -0.94, 0.61, -0.84, 0.78, -0.79, 0.82, -0.46, 0.26,
  -0.35, 0.2, -0.48, 0.28, -0.69, 0.55, -0.61, 0.5, -0.61, 0.46, -0.58, 0.42, -0.41, 0.24, -0.09,
  0.06, -0.05, 0.05, -0.15, 0.15, -0.39, 0.35, -0.43, 0.23, -0.35, 0.23, -0.63, 0.35, -0.62, 0.39,
  -0.22, 0.22, -0.57, 0.43, -0.32, 0.31, -0.06, 0.07, -0.47, 0.23, -0.68, 0.49, -0.6, 0.56, -0.56,
  0.31, -0.49, 0.23, -0.46, 0.25, -0.53, 0.38, -0.3, 0.3, -0.2, 0.19, -0.17, 0.16, -0.08, 0.1,
  -0.07, 0.1, -0.09, 0.09, -0.07, 0.09, -0.48, 0.31, -0.49, 0.24, -0.47, 0.31, -0.28, 0.2, -0.22,
  0.27, -0.24, 0.22, -0.63, 0.47, -0.66, 0.45, -0.43, 0.22, -0.31, 0.13, -0.47, 0.31, -0.67, 0.6,
  -0.7, 0.64, -0.54, 0.4, -0.17, 0.16, -0.53, 0.24, -0.47, 0.34, -0.32, 0.25, -0.51, 0.34, -0.43,
  0.32, -0.3, 0.22, -0.25, 0.18, -0.31, 0.35, -0.53, 0.35, -0.57, 0.44, -0.51, 0.41, -0.54, 0.28,
  -0.54, 0.26, -0.43, 0.21, -0.24, 0.13, -0.16, 0.16, -0.45, 0.34, -0.41, 0.33, -0.36, 0.35, -0.14,
  0.16, -0.09, 0.1, -0.61, 0.47, -0.61, 0.57, -0.44, 0.46, -0.09, 0.08, -0.12, 0.15, -0.12, 0.1,
  -0.31, 0.36, -0.23, 0.26, -0.14, 0.15, -0.32, 0.35, -0.38, 0.38, -0.79, 0.79, -0.75, 0.81, -0.62,
  0.8, -0.41, 0.38, -0.42, 0.45, -0.27, 0.32, -0.2, 0.24, -0.25, 0.32, -0.43, 0.5, -0.39, 0.48,
  -0.18, 0.23, -0.12, 0.1, -0.15, 0.15, -0.39, 0.65, -0.57, 0.8, -0.61, 0.8, -0.65, 0.76, -0.17,
  0.21, -0.32, 0.32, -0.13, 0.15, -0.03, 0.03, -0.06, 0.06, -0.17, 0.19, -0.78, 0.76, -0.8, 0.79,
  -0.55, 0.5, -0.28, 0.28, -0.28, 0.33, -0.91, 0.7, -0.8, 0.55, -0.56, 0.46, -0.23, 0.32, -0.5,
  0.57, -0.46, 0.67, -0.54, 0.61, -0.52, 0.66, -0.56, 0.79, -0.47, 0.83, -0.13, 0.18, -0.42, 0.34,
  -0.6, 0.86, -0.65, 0.74, -0.53, 0.55, -0.72, 0.65, -0.33, 0.35, -0.25, 0.19, -0.43, 0.35, -0.39,
  0.66, -0.52, 0.57, -0.47, 0.49, -0.54, 0.72, -0.5, 0.71, -0.59, 0.61, -0.21, 0.2, -0.57, 0.43,
  -0.52, 0.43, -0.13, 0.12, -0.82, 0.47, -0.56, 0.45, -0.39, 0.26, -0.45, 0.28, -0.27, 0.29, -0.35,
  0.43, -0.43, 0.46, -0.33, 0.35, -0.45, 0.43, -0.47, 0.39, -0.63, 0.76, -0.7, 0.78, -0.57, 0.74,
  -0.08, 0.09, -0.54, 0.8, -0.37, 0.64, -0.45, 0.56, -0.37, 0.46, -0.63, 0.66, -0.65, 0.79, -0.34,
  0.38, -0.63, 0.74, -0.55, 0.65, -0.5, 0.6, -0.71, 0.69, -0.77, 0.8, -0.66, 0.61, -0.48, 0.42,
  -0.67, 0.81, -0.28, 0.17, -0.15, 0.16, -0.58, 0.65, -0.39, 0.6, -0.52, 0.61, -0.44, 0.41, -0.58,
  0.51, -0.27, 0.3, -0.64, 0.65, -0.74, 0.73, -0.25, 0.31, -0.35, 0.38, -0.5, 0.51, -0.4, 0.46,
  -0.2, 0.17, -0.28, 0.3, -0.29, 0.41, -0.28, 0.34, -0.15, 0.18, -0.06, 0.06, -0.05, 0.06, -0.34,
  0.19, -0.39, 0.38, -0.65, 0.55, -0.69, 0.66, -0.54, 0.56, -0.36, 0.27, -0.4, 0.38, -0.24, 0.17,
  -0.09, 0.11, -0.23, 0.13, -0.34, 0.35, -0.56, 0.68, -0.54, 0.52, -0.31, 0.28, -0.2, 0.19, -0.28,
  0.23, -0.65, 0.61, -0.51, 0.46, -0.39, 0.25, -0.46, 0.55, -0.32, 0.41, -0.64, 0.33, -0.27, 0.24,
  -0.13, 0.19, -0.17, 0.17, -0.09, 0.12, -0.6, 0.78, -0.63, 0.78, -0.58, 0.7, -0.55, 0.56, -0.26,
  0.2, -0.5, 0.59, -0.52, 0.66, -0.5, 0.5, -0.13, 0.16, -0.19, 0.17, -0.61, 0.72, -0.58, 0.62,
  -0.46, 0.51, -0.13, 0.13, -0.61, 0.51, -0.72, 0.63, -0.65, 0.58, -0.62, 0.53, -0.33, 0.23, -0.26,
  0.24, -0.66, 0.59, -0.43, 0.35, -0.4, 0.43, -0.21, 0.31, -0.35, 0.2, -0.16, 0.22, -0.57, 0.47,
  -0.53, 0.5, -0.28, 0.44, -0.06, 0.08, -0.32, 0.39, -0.17, 0.14, -0.55, 0.57, -0.34, 0.35, -0.06,
  0.08, -0.24, 0.28, -0.37, 0.46, -0.19, 0.22, -0.13, 0.17, -0.06, 0.04, -0.35, 0.37, -0.65, 0.65,
  -0.68, 0.74, -0.61, 0.62, -0.28, 0.23, -0.58, 0.43, -0.25, 0.25, -0.46, 0.53, -0.65, 0.84, -0.38,
  0.46, -0.26, 0.38, -0.69, 0.88, -0.65, 0.72, -0.6, 0.75, -0.61, 0.69, -0.2, 0.21, -0.1, 0.13,
  -0.17, 0.09, -0.26, 0.25, -0.39, 0.3, -0.43, 0.41, -0.41, 0.42, -0.32, 0.35, -0.31, 0.2, -0.11,
  0.11, -0.09, 0.09, -0.26, 0.14, -0.53, 0.47, -0.46, 0.5, -0.08, 0.11, -0.09, 0.09, -0.1, 0.16,
  -0.98, 0.7, -0.28, 0.26, -0.99, 0.79, -0.69, 0.57, -0.32, 0.31, -0.31, 0.28, -0.2, 0.2, -0.49,
  0.42, -0.81, 0.72, -0.57, 0.5, -0.5, 0.6, -0.51, 0.5, -0.54, 0.43, -0.69, 0.64, -0.65, 0.68,
  -0.69, 0.67, -0.47, 0.56, -0.67, 0.48, -0.76, 0.81, -0.6, 0.5, -0.49, 0.46, -0.44, 0.35, -0.23,
  0.23, -0.46, 0.52, -0.6, 0.42, -0.4, 0.35, -0.44, 0.39, -0.46, 0.66, -0.88, 0.76, -0.35, 0.37,
  -0.17, 0.16, -0.4, 0.48, -0.4, 0.31, -0.44, 0.59, -0.26, 0.24, -0.33, 0.49, -0.46, 0.39, -0.11,
  0.1, -0.49, 0.62, -0.34, 0.53, -0.34, 0.34, -0.86, 0.82, -0.51, 0.5, -0.48, 0.39, -0.16, 0.13,
  -0.28, 0.28, -0.39, 0.56, -0.39, 0.48, -0.86, 0.89, -0.52, 0.65, -0.32, 0.43, -0.46, 0.55, -0.46,
  0.99, -0.29, 0.47, -0.33, 0.35, -0.31, 0.34, -0.31, 0.46, -0.3, 0.39, -0.54, 0.49, -0.24, 0.36,
  -0.07, 0.06, -0.08, 0.07, -0.15, 0.12, -0.23, 0.31, -0.13, 0.21, -0.35, 0.48, -0.41, 0.78, -0.28,
  0.47, -0.31, 0.5, -0.47, 0.65, -0.21, 0.4, -0.09, 0.24, -0.08, 0.13, -0.65, 0.55, -0.2, 0.22,
  -0.06, 0.05, -0.02, 0.03, -0.64, 0.72, -0.65, 0.65, -0.88, 0.62, -0.66, 0.57, -0.28, 0.35, -0.17,
  0.17, -0.57, 0.57, -0.46, 0.49, -0.1, 0.12, -0.09, 0.06, -0.5, 0.57, -0.5, 0.55, -0.62, 0.59,
  -0.31, 0.32, -0.32, 0.42, -0.2, 0.34, -0.11, 0.13, -0.35, 0.47, -0.36, 0.54, -0.27, 0.23, -0.35,
  0.32, -0.19, 0.17, -0.62, 0.53, -0.43, 0.53, -0.83, 0.65, -0.65, 0.89, -0.57, 0.8, -0.46, 0.65,
  -0.34, 0.51, -0.35, 0.42, -0.46, 0.63, -0.57, 0.37, -0.33, 0.28, -0.31, 0.35, -0.27, 0.35, -0.26,
  0.21, -0.12, 0.18, -0.25, 0.19, -0.25, 0.19, -0.72, 0.35, -0.66, 0.54, -0.06, 0.12, -0.51, 0.63,
  -0.85, 0.76, -0.48, 0.27, -0.25, 0.28, -0.38, 0.39, -0.69, 0.77, -0.54, 0.44, -0.43, 0.43, -0.34,
  0.41, -0.28, 0.31, -0.4, 0.23, -0.52, 0.51, -0.32, 0.28, -0.33, 0.51, -0.23, 0.25, -0.08, 0.09,
  -0.62, 0.44, -0.15, 0.17, -0.3, 0.45, -0.2, 0.39, -0.14, 0.15, -0.24, 0.19, -0.14, 0.1, -0.28,
  0.2, -0.28, 0.54, -0.13, 0.21, -0.13, 0.12, -0.26, 0.22, -0.43, 0.46, -0.51, 0.75, -0.5, 0.57,
  -0.44, 0.31, -0.25, 0.2, -0.41, 0.35, -0.32, 0.24, -0.08, 0.09, -0.43, 0.57, -0.48, 0.48, -0.43,
  0.41, -0.46, 0.5, -0.38, 0.32, -0.31, 0.34, -0.13, 0.11, -0.06, 0.07, -1.0, 0.82, -0.25, 0.23,
  -1.0, 0.94, -0.47, 0.51, -0.19, 0.2, -0.31, 0.27, -0.21, 0.19, -0.41, 0.41, -0.45, 0.49, -0.32,
  0.28, -0.23, 0.4, -0.22, 0.28, -0.18, 0.28, -0.12, 0.19, -0.06, 0.08, -0.06, 0.05, -0.04, 0.04,
  -0.02, 0.02, -0.02, 0.02, -0.02, 0.02, -0.02, 0.02, -0.02, 0.01, -0.01, 0.01, -0.01, 0.01, -0.01,
  0.01, -0.01, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
];
