import { FC } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuButton from 'components/MenuButton';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChatIcon from '@mui/icons-material/Chat';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import VerifiedIcon from '@mui/icons-material/Verified';

import CustomAvatar from 'components/CustomAvatar';
import { UserRole } from 'interfaces/userInterface';
import { useAppSelector } from 'hooks/useAppSelector';
import { ReactComponent as Logo } from './truefans-dark.svg';
import Notifications from './Notifications';
import { useStyles } from './styles/main';

const MainHeader: FC = () => {
  const { classes } = useStyles();
  const { user } = useAppSelector(state => state.auth);

  return (
    <header className={classes.header}>
      <Stack direction="row">
        <Stack
          mr="auto"
          alignItems="flex-start"
          component="a"
          target="_blank"
          href={process.env.REACT_APP_WEBSITE_HOST}
        >
          <Logo width={197} height={50} />
        </Stack>

        {user && (
          <Stack direction="row" spacing={3} alignItems="center">
            <Notifications userRole={user.role} />

            <Typography className="medium">
              Logged in :&nbsp;
              <strong>{user.name || user.username}</strong>
            </Typography>

            <CustomAvatar username={user.username} src={user.avatar} />

            {user.role === UserRole.CREATOR && (
              <MenuButton>
                <MenuItem
                  component="a"
                  target="_blank"
                  href={`${process.env.REACT_APP_WEBSITE_HOST}/feedback`}
                >
                  <ListItemIcon>
                    <ChatIcon />
                  </ListItemIcon>

                  <ListItemText>Feedback</ListItemText>
                </MenuItem>

                <MenuItem
                  component="a"
                  target="_blank"
                  href={`${process.env.REACT_APP_WEBSITE_HOST}/blog`}
                >
                  <ListItemIcon>
                    <NewspaperIcon />
                  </ListItemIcon>

                  <ListItemText>Blog</ListItemText>
                </MenuItem>

                <MenuItem component="a" target="_blank" href={process.env.REACT_APP_VALIDATOR_URL}>
                  <ListItemIcon>
                    <VerifiedIcon />
                  </ListItemIcon>

                  <ListItemText>Validator</ListItemText>
                </MenuItem>
              </MenuButton>
            )}
          </Stack>
        )}
      </Stack>
    </header>
  );
};

export default MainHeader;
