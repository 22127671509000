import { FC } from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import { Color } from 'theme/muiTheme';
import { ReactComponent as TrueFansSVG } from 'assets/svg/fans.svg';

const StyledBox = styled(Box)(() => ({
  backgroundColor: Color.WHITE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',

  '& > svg': {
    width: '50%',
    height: '70%'
  }
}));

interface PersonAvatarProps extends AvatarProps {
  usePrefix?: boolean;
  width?: number | string;
  height?: number | string;
  outline?: boolean;
  online?: boolean;
  path?: string;
  username?: string;
}

const CustomAvatar: FC<PersonAvatarProps> = ({
  usePrefix = true,
  outline = true,
  path,
  src,
  width,
  height,
  online,
  username,
  ...rest
}) => {
  const imgSrc =
    usePrefix && src ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${encodeURIComponent(src)}` : src;

  const avatar = (
    <Avatar
      src={imgSrc}
      {...rest}
      sx={{ width, height, outline: outline ? `2px solid ${Color.PRM_200}` : 'none' }}
    >
      <StyledBox>
        <TrueFansSVG />
      </StyledBox>
    </Avatar>
  );

  return path || username ? (
    <IconButton
      color="primary"
      target="_blank"
      rel="noopener"
      component={Link}
      sx={{
        width: '58px',
        height: '58px',
        ...(rest.variant === 'square'
          ? { borderRadius: 0, width: 'min-content', height: 'min-content', padding: 0 }
          : {})
      }}
      href={`${process.env.REACT_APP_WEBSITE_HOST}/${path || `fans/${username}`}`}
    >
      {avatar}

      {online && (
        <span
          style={{
            position: 'absolute',
            bottom: '8px',
            right: '4px',
            display: 'inline-block',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: Color.GREEN
          }}
        />
      )}
    </IconButton>
  ) : (
    avatar
  );
};

export default CustomAvatar;
