import { makeStyles } from 'tss-react/mui';

import { Color } from 'theme/muiTheme';

export const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  header: {
    padding: '1rem 3rem',
    borderBottom: `1px solid ${Color.BORDER}`
  },
  container: {
    display: 'flex',
    flex: 1
  },
  main: {
    flex: 1,
    padding: '2.5rem 3rem',
    maxWidth: 'calc(100% - 6rem)'
  },
  list: {
    margin: '3rem 0 6rem',
    listStylePosition: 'inside',

    '& > li': {
      marginBottom: '1.5rem'
    }
  },
  checkBox: {
    position: 'absolute',
    bottom: 0,
    right: 16,

    '& small': {
      opacity: 0.6
    }
  }
}));
