import { FC, useState, MouseEvent, ReactNode, useRef, useEffect, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import Button, { ButtonProps } from '@mui/material/Button';
import { PopoverOrigin } from '@mui/material/Popover';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';

interface MenuButtonProps extends PropsWithChildren {
  buttonNode?: ReactNode;
  className?: string;
  buttonProps?: ButtonProps;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  closeOnClick?: boolean;
}

const MenuButton: FC<MenuButtonProps> = ({
  children,
  buttonNode,
  buttonProps,
  className,
  transformOrigin,
  anchorOrigin,
  closeOnClick
}) => {
  const initialRender = useRef(true);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (evt: MouseEvent<HTMLElement>) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    handleClose();
  }, [location.pathname]);

  return (
    <>
      <Button
        {...buttonProps}
        onClick={handleClick}
        aria-label={`${anchorEl ? 'active' : 'close'}`}
      >
        {buttonNode || <MenuIcon color="action" />}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        MenuListProps={{
          className,
          onClick: closeOnClick ? handleClose : undefined
        }}
      >
        {children}
      </Menu>
    </>
  );
};

MenuButton.defaultProps = {
  className: '',
  buttonProps: { color: 'secondary' },
  transformOrigin: { horizontal: 'right', vertical: 'top' },
  anchorOrigin: { horizontal: 'right', vertical: 60 }
};

export default MenuButton;
