import { CurrencyUnit, MongoDoc } from './general';

export enum UserRole {
  ADMIN = 'admin',
  CREATOR = 'creator',
  FAN = 'fan'
}

export interface IUser extends MongoDoc {
  username: string;
  displayName?: string;
  email: string;
  role: UserRole;
  lastLoginDate?: string;
  name?: string;
  color?: string;
  avatar?: string;
  about?: string;
  currency?: CurrencyUnit;
  tags?: string[];
  podcast?: string;
  featured?: boolean;
  archived?: boolean;
}

export type UserFormType = Omit<IUser, 'createdAt' | 'role'>;

export type BriefUser = Pick<IUser, 'name' | 'username' | 'avatar' | '_id' | 'displayName'>;

export type EnhancedUser = Pick<
  IUser,
  | 'name'
  | 'username'
  | 'avatar'
  | '_id'
  | 'createdAt'
  | 'email'
  | 'featured'
  | 'role'
  | 'lastLoginDate'
  | 'archived'
> & {
  podcastRole?: UserRole;
  sendEmail?: boolean;
  superfan?: boolean;
  supporter?: boolean;
  online?: boolean;
  lastPlayed?: string;
  leaderboardPosition?: number;
};

export interface IUserWallet {
  customKey: string;
  customValue: string;
  pubKey: string;
  lightningAddress?: string;
  sats?: number;
}

export interface EmailBodyType {
  subject: string;
  text: string;
  userId: string;
  podcastId?: string;
}

export type UsersWalletInfoType = Record<string, IUserWallet>;

export enum WaitlistStatus {
  WAITING = 'waiting',
  INVITED = 'invited',
  USER = 'user'
}

export interface IWaitList {
  _id: string;
  name: string;
  email: string;
  status: WaitlistStatus;
  createdAt: string;
  user?: string;
  invitedAt?: string;
  joinedAt?: string;
  albyWallet?: boolean;
  verified?: boolean;
}

export type EditWaitListUserForm = Pick<IWaitList, 'name' | 'email' | 'albyWallet'>;

export enum NotificationType {
  NEW_PODCAST = 'Podcast',
  NEW_EPISODE = 'Episode',
  NEW_LIVE_SHOW = 'Live',
  LIVE_SHOW_PENDING = 'Pending',
  REPLY = 'Reply',
  FOLLOW = 'Follow',
  UNFOLLOW = 'Unfollow'
}

export enum DashboardNotificationType {
  FLAG = 'Flag',
  INCORRECT_SPLIT = 'Split',
  NEW_FEATURE = 'New Feature',
  NEW_BUG = 'New Bug'
}

export interface INotification {
  _id: string;
  type: NotificationType | DashboardNotificationType;
  seen: boolean;
  date: string;
  user?: BriefUser;
  flagger?: BriefUser;
  boost?: unknown;
  clip?: unknown;
  feedback?: {
    _id: string;
    user: BriefUser;
  };
  episode?: {
    _id: string;
    title: string;
    image?: string;
    podcast: {
      _id: string;
      title: string;
      slug: string;
      image: string;
    };
  };
  podcast?: {
    _id: string;
    title: string;
    slug: string;
    image: string;
  };
}

export type UserMetaDataType = {
  wallet: { amount: number };
  clipCount: number;
  boostCount: number;
  totalListenTime: number;
  playCount: number;
  followingCount: number;
  lastPlayed?: string;
  totalPaid?: number;
  totalFee?: number;
  points?: number;
};

export type FanType = Pick<
  IUser,
  'name' | 'username' | '_id' | 'createdAt' | 'avatar' | 'about' | 'displayName'
> &
  UserMetaDataType;
